import { useEffect, useRef, useState } from 'react';
import { Outlet } from 'react-router-dom';

const Layout = () => {
  const modalRef = useRef(null);
  const [isModalOpen, setModalOpen] = useState(false);
  useEffect(() => {
    function handleClickOutside(event) {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        // Close modal here
      }
    }

    // Attach the click event handler
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [modalRef]);

  return (
    <>
    
      <nav className="bg-white border-gray-200 dark:bg-black">
        <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
          <a href="https://6uogames.com/" className="flex items-center">
              <img src="/6uologo.svg" className="h-10 mr-3" alt="6UO Logo" />
              <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">6UO GAMES</span>
          </a>
          {/* <button onClick={()=>setModalOpen(true)} data-collapse-toggle="navbar-default" type="button" className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-controls="navbar-default" aria-expanded="false">
              <span className="sr-only">Open main menu</span>
              <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15"/>
              </svg>
          </button> */}
          <div className="hidden w-full md:block md:w-auto" id="navbar-default">
            <ul className="font-medium flex flex-col p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:border-0 md:bg-white dark:bg-black md:dark:bg-black dark:border-gray-700">
              {/* <li>
                <a href="#" className="block py-2 pl-3 pr-4 text-white bg-[#8ad1c2] rounded md:bg-transparent md:text-[#8ad1c2] md:p-0 dark:text-white md:dark:text-[#8ad1c2]" aria-current="page">Home</a>
              </li>
              <li>
                <a href="#" className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-[#8ad1c2] md:p-0 dark:text-white md:dark:hover:text-[#8ad1c2] dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">About</a>
              </li>
              <li>
                <a href="#" className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-[#8ad1c2] md:p-0 dark:text-white md:dark:hover:text-[#8ad1c2] dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">Services</a>
              </li>
              <li>
                <a href="#" className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-[#8ad1c2] md:p-0 dark:text-white md:dark:hover:text-[#8ad1c2] dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">Pricing</a>
              </li>
              <li>
                <a href="#" className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-[#8ad1c2] md:p-0 dark:text-white md:dark:hover:text-[#8ad1c2] dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">Contact</a>
              </li> */}
            </ul>
          </div>
        </div>
      </nav>

      <Outlet />

      {isModalOpen && (
        <div className="fixed inset-0 bg-opacity-50 max-h-screen" onClick={() => setModalOpen(false)}></div>
      )}

      <div 
        ref={modalRef} 
        className={`fixed z-50 top-0 right-0 h-full w-128 bg-gray-50/95 dark:bg-gray-800 dark:text-white overflow-auto transform transition-transform ease-in-out duration-300 ${
        isModalOpen ? 'translate-x-0' : 'translate-x-full'
        }`}
      >
        <div className="p-4">
          
              <a href="#" className="block mt-12 py-2 pl-3 pr-4 text-white bg-[#8ad1c2] rounded md:bg-transparent md:text-[#8ad1c2] md:p-0 dark:text-white md:dark:text-[#8ad1c2]" aria-current="page">Home</a>
            
              <a href="#" className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-[#8ad1c2] md:p-0 dark:text-white md:dark:hover:text-[#8ad1c2] dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">About</a>
            
              <a href="#" className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-[#8ad1c2] md:p-0 dark:text-white md:dark:hover:text-[#8ad1c2] dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">Services</a>
            
              <a href="#" className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-[#8ad1c2] md:p-0 dark:text-white md:dark:hover:text-[#8ad1c2] dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">Pricing</a>
            
              <a href="#" className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-[#8ad1c2] md:p-0 dark:text-white md:dark:hover:text-[#8ad1c2] dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">Contact</a>
            
        </div>
      </div>

    </>
  )
};

export default Layout;